import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Layout from '../components/layout';
import SocialRef from '../components/SocialRef';
import FromTheNews from '../components/FromTheNews';
import './single-film.scss';

const FilmCamLang = () => (
  <Layout className="single-film">
    <section className="film-hero">
      <Container>
          <Row>
            <Col xs="12" md="5" className="mb-4">
              <div className="text-center">
                <Fade>
                <img loading="lazy" src="/files/poster-film-stay-awake-be-ready.jpg" alt="STAY AWAKE, BE READY – HÃY TỈNH THỨC VÀ SẴN SÀNG (2019) Film ngắn của đạo diễn Phạm Thiên Ân"/>
                </Fade>
              </div>
            </Col>
            <Col xs="12" md="7">
              <h1 className="film-title">
              STAY AWAKE, BE READY – HÃY TỈNH THỨC VÀ SẴN SÀNG (2019)
              </h1>
              <div className="film-desc">
              On a street corner a mysterious conversation among three young men at a street stalls. Meanwhile a traffic accident on a motorbike. The night brings together a sketch, a multi color frame of reality.
              </div>
              <div className="film-specs">
                <div>
                  <strong>Genres:</strong> Short | Drama
                </div>
                <div>
                  <strong>Language:</strong> Vietnamese
                </div>
                <div>
                  <strong>Release Date:</strong> 20 June 2018 (USA)
                </div>
                <div>
                  <strong>Director:</strong> Pham Thien An
                </div>
                <div>
                  <strong>Writer:</strong> Pham Thien An
                </div>
                <div>
                  <strong>Key Cast:</strong> Pham Le Kim Ngoc, Dan Le, Nguyen Tan, Nguyen Lam Quynh Han
                </div>
              </div>
              <SocialRef
                facebook="https://www.facebook.com/stayawake.beready/"
                imdb="https://www.imdb.com/title/tt10402938/"
                vimeo="https://vimeo.com/331872264"
              />
            </Col>
          </Row>
      </Container>
    </section>
    <Container>
      <Row>
        <Col md="8">
          <div className="video-responsive">
            <iframe src="https://player.vimeo.com/video/331872264" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
            <p><a href="https://vimeo.com/331872264">H&atilde;y Tỉnh Thức V&agrave; Sẵn S&agrave;ng | Stay Awake, Be Ready | Trailer - Pham Thien An</a> from <a href="https://vimeo.com/phamthienan">Pham Thien An</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
          </div>
          <img className="mb-4" src="/files/canh-trong-film-stay-awake-be-ready.jpg" alt="Film scene STAY AWAKE, BE READY – HÃY TỈNH THỨC VÀ SẴN SÀNG (2019)"/>
          <img className="mb-4" src="/files/canh-trong-film-stay-awake-be-ready-2.jpg" alt="Film scene STAY AWAKE, BE READY – HÃY TỈNH THỨC VÀ SẴN SÀNG (2019)"/>
          <img className="mb-4" src="/files/canh-trong-film-stay-awake-be-ready-3.jpg" alt="Film scene STAY AWAKE, BE READY – HÃY TỈNH THỨC VÀ SẴN SÀNG (2019)"/>
          <FromTheNews />
        </Col>
        <Col md="4">
          <div className="widget">
            <div className="widget-title">
              Director's statement
            </div>
            <div className="widget-content">
            Street pubs & stalls are places where people in Vietnam can chat many things without fear of being judged. Through the conversation of three young men before the street stalls on a street corner, a traffic accident happened suddenly nearby, the director wanted to sketch a multi-color picture of the humanity. When society makes people rush day by day, they often think of themselves as the center of the universe and actually forget how small people really are in the big world.
            </div>
          </div>
          <div className="widget">
            <div className="widget-title">
            Distributor
            </div>
            <div className="widget-content">
              <div>
              Lights On Film
              </div>
              <a rel="noopener noreferrer" aria-label="Lights On Film" href="https://www.lightsonfilm.com/" target="_blank">www.lightsonfilm.com</a>
            </div>
          </div>

          <div className="widget">
            <div className="widget-title">
            Festival Selection
            </div>
            <div className="widget-content">
              <a href="https://www.quinzaine-realisateurs.com" target="_blank">Quinzaine des Réalisateurs</a> - Cannes 2019, France - <strong><span>World Premiere, Illy Award </span></strong>
              <br /><a href="https://www.locarnofestival.ch/en" target="_blank">Locarno Film Festival</a>
              2019, Switzerland
              <br /><a href="http://www.kinemastik.org" target="_blank">Kinemastik International Short Film Festival</a>
              2019, Malta
              <br /><a href="https://www.encounters.film" target="_blank">Encounters Film Festival</a><span> 2019, UK</span>
              <br />​<a href="http://csff.co/" target="_blank">Chaktomuk Short Film Festival </a><span>2019, Cambodia</span>
              <br /><a href="http://pacificmeridianfest.ru/" target="_blank">Pacific Meridian - International Film Festival</a>
              2019, Russia
              <br /><a href="https://message2man.com/en/" target="_blank">“Message to Man” International Film Festival</a>
              2019, Russia<span>​</span>
              <br /><a href="https://www.raindance.org/festival" target="_blank">Raindance Film Festival</a>
              2019, UK
              <br /><span>​</span><a href="http://www.biaff.org" target="_blank">Batumi International Art-House Film Festival </a>2019, Georgia
              <br /><a href="http://www.biff.kr/eng" target="_blank">Busan International Film Festival</a>
              2019, South Korea
              <br /><a href="http://www.splitfilmfestival.hr/" target="_blank">Split Film Festival</a>
              2019, Croatia
              <br /><a href="https://www.balinale.com/" target="_blank">Bali International Film Festival</a>
              2019, Indonesia
              <br />​<a href="https://altinkozaff.org.tr/en/home-2/" target="_blank">International Adana Golden Boll Film Festival</a><span> 2019, Turkey</span>
              <br /><a href="https://www.viff.org" target="_blank">Vancouver International Film Festival</a>
              2019, Canada
              <br /><a href="https://nashvillefilmfestival.org/" target="_blank">Nashville Film Festival </a><span>2019, USA</span>
              <br /><a href="http://www.milanofilmfestival.it/en/" target="_blank">Milano Film Festival </a>2019, Italy
              <br /><a href="https://heartofgold.com.au/" target="_blank">Heart Of Gold International Short Film Festival </a>2019, Australia
              <br /><a href="https://www.kff.tw/EN" target="_blank">Kaohsiung Film Festival</a><span> 2019, Taiwan</span>
              <br /><a href="http://www.isffc.com.cy" target="_blank">International Short Film Festival of Cyprus</a>
              2019
              <br /><a href="http://www.bbff.com.au/" target="_blank">Byron Bay International Film Festival </a>2019, Australia
              <br />​<a href="https://www.seminci.es/en/" target="_blank">Semana Internacional de Cine de Valladolid - Seminci</a><span> 2019, Spain - </span><strong><span>Best International Short, “Meeting Point” Section</span></strong>
              <br /><a href="http://www.tisff.eu/" target="_blank">Thess International Short Film Festival </a><span>2019, Greece</span>
              <br /><a href="http://www.edinburghshortfilmfestival.com/" target="_blank">Edinburgh Short Film Festival</a>
              2019, Scotland - <strong>Best Film</strong>
              <br /><a href="https://festivalcinema.ca/" target="_blank">Festival du cinéma international en Abitibi-Témiscamingue</a>
              2019, Canada
              <br /><a href="http://curtacinema.com.br/home/" target="_blank">Festival Curta Cinema – Rio de Janeiro International Short Film Festival </a>2019, Brazil - <strong><span>Best Director Award</span></strong>
              <br /><a href="https://www.denverfilm.org/" target="_blank">Denver Film Festival </a>2019, USA
              <br /><a href="https://www.sgiff.com/" target="_blank">Singapore International Film Festival</a><span> 2019</span>
              <br /><a href="https://www.stockholmfilmfestival.se/en" target="_blank">Stockholm International Film Festival </a><span>2019, Sweden</span>
              <br /><a href="http://www.izmirkisafilm.org/" target="_blank">Izmir International Short Film Festival</a><span> 2019, Turkey</span>
              <br /><a href="http://www.bieff.ro/en" target="_blank">Bucharest International Experimental Film Festival </a>2019, Romania
              <br /><a href="https://sdaff.org/" target="_blank">San Diego Asian Film Festival</a>
              2019, USA
              <br /><a href="https://jaff-filmfest.org/" target="_blank">Jogja-NETPAC Asian Film Festival </a><span>2019, Indonesia</span>
            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              14min | Vietnam, South Korea, USA | 2019  <br />

              Year of production 2019 <br />
              Length 14 min <br />
              Country Vietnam, South Korea, USA <br />
              Shooting Format ARRI Alexa <br />
              Aspect Ratio 1:1,85 <br />
              Sound 5.1 <br />
              Dialogue Vietnamese

            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              <strong>Director</strong> Pham Thien An <br/>
              <strong>Producers</strong> Pham Thien An, Tran Van Thi <br/>
              <strong>Production</strong> JKFILM <br/>
              <strong>Writer</strong> Pham Thien An <br/>
              <strong>Cinematographer</strong> Dinh Duy Hung <br/>
              <strong>Production Designer</strong> Huynh Phuong Hien, Nguyen Minh Quyen <br/>
              <strong>Production Coordinator</strong> Pham Thi Phuong Thao, Nguyen Lam Quynh Han <br/>
              <strong>Production Assistant</strong> Diep Phuong Uyen, Pham Thien Phuc<br/>
              <strong>Editor</strong> Pham Thien An <br/>
              <strong>Director Assistant</strong> Pham Tuan Vu<br/>
              <strong>Camera Assistant</strong> Do Tien Dat, Nguyen Tri Vuong Ba <br/>
              <strong>Sound Mixing</strong> Tamas Steger<br/>
              <strong>Sound Recording</strong> Do Vu<br/>
              <strong>BTS Still</strong> Luu Binh An <br/>
              <strong>Key Cast</strong> Le Quoc Bao Vi, Vu Trong Tuyen, Trung Dong Cao, Be Hieu, Huynh Ngoc Hanh
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default FilmCamLang;
